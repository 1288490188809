<template>
  <div class="h-100 users-list">
    <base-list
      :headers="headers"
      :items="items"
      :fetching="fetching"
      @onEditClick="$emit('onEditClick')"
      @onDeleteClick="$emit('onDeleteClick')"
      @nameClicked="nameClickEvent"
      :role="selectedRole"
      :search="search"
      @cleargetvalue="onclearValue"
      @setRowsData="setRows"
      :isSetRows="true"
    >
    </base-list>
  </div>

</template>

<script>
import moment from 'moment-shortformat';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { truncateText } from '@/helpers';
import BtnCellRenderer from '@/components/projects/ProjectTeam/BtnCellRenderer';
import BaseList from './common/BaseList';

export default {
  components: {
    BaseList,
  },
  props: {
    users: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: '',
    },
    fetching: {
      type: Boolean,
      default: false,
    },
    selectedRole: {
      type: String,
      default: '',
    },
  },


  computed: {
    ...mapGetters('user', ['userDetails']),
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    headers() {
      const $this = this;
      let items = [];
      switch (this.selectedRole) {
        case 'producer':
          items = [
            $this.nameColumn(),
            'email', 'phone',
            $this.roleColumn(),
            // $this.dateColumn(),
            $this.statusColumn(),
          ];
          break;
        case 'client':
          items = [
            $this.nameColumn(),
            'email', 'phone', $this.businessVerticalColumn(), $this.businessColumn(),
            $this.dateColumn(),
            $this.roleColumn(),
            $this.statusColumn(),
          ];
          break;
        case 'creative':
          items = [
            $this.newTagColumn(),
            $this.nameColumn(),
            'email', $this.activeProjectCount(), 'phone', $this.zipCodeColumn(), $this.skillsetColumn(),
            $this.radiusColumn(),
            $this.dateColumn(),
            $this.statusColumn(),
          ];
          break;
        default: // 'Admin'
          items = [
            $this.nameColumn(),
            'email', 'phone',
            $this.dateColumn(),
            $this.statusColumn(),
          ];
      }
      if ($this.selectedRole !== 'admin') {
        if (this.$can('edit', `${$this.selectedRole} management`)) {
          items.push($this.editColumn());
          if (this.role === 'admin') {
            items.push($this.deleteColumn());
          }
        } else {
          items.push($this.viewColumn());
        }
      }
      return items;
    },
    items() {
      switch (this.selectedRole) {
        case 'client':
          return _.map(this.users, (user) => ({
            id: user.id,
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
            'create date': user.createdDate ? moment(user.createdDate).format('M/D/YY') : null,
            phone: user.number,
            status: this.getStatus(user.status),
            business: truncateText(_.get(user.business, 'businessName'), 20),
            'Business vertical': _.get(user.business, 'businessType.shortName', ''),
            deletedAt: user.deletedAt,
            role: this.getClientRole(_.get(user, 'role.name', '')),
          }));
        case 'creative':
          return _.map(this.users, (user) => ({
            id: user.id,
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
            activeProjectsCount: user.activeProjectsCount,
            'create date': user.createdDate ? moment(user.createdDate).format('M/D/YY') : null,
            zipcode: user.zipcode,
            phone: user.number,
            radius: user.radius,
            skillset: this.skillsetWithPaytiers(_.get(user.creativeProfile, 'skillsets', []), user.payTiers),
            status: this.getStatus(user.status),
            newTag: user.newTag,
            deletedAt: user.deletedAt,
          }));
        default:
          return _.map(this.users, (user) => ({
            id: user.id,
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
            role: this.getRole(_.get(user, 'role.name', '')),
            'create date': user.createdDate ? moment(user.createdDate).format('M/D/YY') : null,
            phone: user.number,
            status: this.getStatus(user.status),
            deletedAt: user.deletedAt,
            isDefaultContact: user.isDefaultContact,
          }));
      }
    },
  },
  methods: {
    setRows(data) {
      this.$emit('setDisplayedRows', data);
    },
    nameClickEvent(data) {
      this.$emit('nameClicked', data);
    },
    onclearValue(value) {
      this.$emit('cleargetvalue', value);
    },
    skillsetNames(skillsets) {
      return skillsets.map((x) => (x.name === 'Other' ? x.skillSetName : x.name)).join(', ');
    },
    skillsetWithPaytiers(skillsets, paytiers) {
      const skills = [];
      _.map(skillsets, (skill) => {
        if (skill.name === 'Other') {
          skills.push(skill.skillSetName);
        } else if (skill.name === 'Talent') {
          const uniqPayTiers = _.uniqBy(paytiers, 'payTier');
          const payTiers = _.map(_.filter(uniqPayTiers, ['skillSetId', skill.id]), 'payTier').join(', ');
          skills.push(payTiers ? `${skill.name} - ${payTiers}` : skill.name);
        } else {
          const paytier = _.find(paytiers, ['skillSetId', skill.id]);
          skills.push(paytier ? `${skill.name} - ${paytier.payTier}` : skill.name);
        }
      });
      return skills.join(',<br/>');
    },
    getStatus(status) {
      switch (status) {
        case '0':
          return 'Inactive';
        case '2':
          return 'Limited';
        case '3':
          return 'Blocked';
        case '4':
          return 'Rejected';
        default:
          return 'Active';
      }
    },
    getRole(role) {
      switch (role) {
        case 'executive':
          return 'Production Manager';
        case 'producer':
          return 'Content Producer';
        default:
          return _.startCase(role);
      }
    },
    getClientRole(role) {
      switch (role) {
        case 'agency_owner':
          return 'Organization Owner';
        case 'agency_member':
          return 'Organization Member';
        case 'client':
          return 'Owner';
        case 'member':
          return 'Member';
        default:
          return _.startCase(role);
      }
    },
    editClick(val) {
      this.$emit('onEditClick', val, this.selectedRole === 'producer');
    },
    deleteClick(val) {
      this.$emit('onDeleteClick', val);
    },
    roleColumn() {
      return {
        headerName: 'role',
        field: 'role',
        sortable: true,
        filter: true,
        width: 200,
        cellRenderer: (params) => {
          if (params.data.isDefaultContact) {
            return `${params.value} (Primary)`;
          }
          return params.value;
        },
      };
    },
    nameColumn() {
      return {
        field: 'name',
        sortable: true,
        comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
        filter: true,
        cellClass: 'notranslate',
        cellStyle: { cursor: this.selectedRole === 'creative' && 'pointer' },
      };
    },
    radiusColumn() {
      return {
        field: 'radius',
        filter: true,
        width: 110,
        cellClass: 'notranslate',
        autoHeight: true,
        cellStyle: { textAlign: 'left', marginLeft: '15px !important' },
      };
    },
    statusColumn() {
      return {
        headerName: 'status',
        field: 'status',
        sortable: true,
        width: 95,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: this.statusFilters(),
          suppressAndOrCondition: true,
        },
      };
    },
    activeProjectCount() {
      return {
        headerName: 'Projects',
        field: 'activeProjectsCount',
        filter: true,
        width: 130,
        autoHeight: true,
        cellStyle: { textAlign: 'left', marginLeft: '25px !important' },
      };
    },
    businessVerticalColumn() {
      return {
        headerName: 'Business vertical',
        field: 'Business vertical',
        filter: true,
        tooltipField: 'Business vertical',
        cellClass: 'notranslate',
        cellStyle: { textAlign: 'left' },
      };
    },
    businessColumn() {
      return {
        headerName: 'Business',
        field: 'business',
        filter: true,
        tooltipField: 'business',
        cellClass: 'notranslate',
        cellStyle: { textAlign: 'left' },
      };
    },
    zipCodeColumn() {
      return {
        headerName: 'Zip Code',
        field: 'zipcode',
        sortable: true,
        filter: true,
        width: 140,
        autoHeight: true,
        cellStyle: { textAlign: 'left' },
      };
    },
    skillsetColumn() {
      return {
        headerName: 'Skill Set',
        field: 'skillset',
        cellClass: 'skillset text-capitalize',
        width: 140,
        sortable: true,
        filter: true,
        autoHeight: true,
        cellRenderer(params) {
          return params.data.skillset;
        },
      };
    },
    dateColumn() {
      return {
        field: 'create date',
        sortable: true,
        filter: true,
        width: 140,
        comparator: (date1, date2) => moment(date2) - moment(date1),
      };
    },
    editColumn() {
      const $this = this;
      return {
        headerName: 'Edit',
        field: 'id',
        cellClass: 'action pa-2 d-flex justify-center cursor-pointer',
        cellRendererFramework: BtnCellRenderer,
        cellRendererParams: (data) => {
          if (data.data.deletedAt === null) {
            return ({
              image: 'edit',
              clicked(params) {
                $this.editClick(params.value);
                return false;
              },
            });
          }
          return {};
        },
        width: 60,
      };
    },
    newTagColumn() {
      return {
        headerName: '',
        field: 'id',
        cellRendererFramework: BtnCellRenderer,
        cellRendererParams: (data) => {
          if (data.data.newTag === true && data.data.status === 'Active') {
            return ({
              icon: 'mdi-new-box',
              color: '#f29f33',
            });
          }
          return {};
        },
        width: 50,
        cellClass: 'newTagColumn',
      };
    },
    deleteColumn() {
      const $this = this;
      return {
        headerName: '',
        field: 'id',
        cellClass: 'action pa-2 d-flex justify-center cursor-pointer',
        cellRendererFramework: BtnCellRenderer,
        cellRendererParams: (data) => {
          if (data.data.deletedAt === null) {
            return {
              image: 'trash',
              clicked(params) {
                $this.deleteClick(params.value);
                return false;
              },
            };
          }
          return {
            icon: 'mdi-restore',
            color: '#8066FF',
            clicked(params) {
              $this.deleteClick(params.value);
              return false;
            },
          };
        },
        width: 60,
      };
    },
    viewColumn() {
      const $this = this;
      return {
        headerName: 'View',
        field: 'id',
        cellClass: 'action pa-2 d-flex justify-center',
        cellRendererFramework: BtnCellRenderer,
        cellRendererParams: {
          icon: 'mdi-eye',
          color: '#8066FF',
          clicked(params) {
            $this.editClick(params.value);
            return false;
          },
        },
        width: 60,
      };
    },
    statusFilters() {
      const statuses = ['Active', 'Inactive', 'Blocked'];
      if (this.selectedRole === 'creative') {
        statuses.push('Rejected');
      }
      const filters = _.map(statuses, (filter) => ({
        displayKey: filter,
        displayName: filter,
        test(filterValue, cellValue) {
          return cellValue === filter;
        },
        hideFilterInput: true,
      }));
      filters.unshift('empty');
      return filters;
    },
  },
};
</script>

<style lang="scss" scoped>
.users-list .ag-cell.skillset {
    line-height: 1.25 !important;
  }
</style>
