<template>
  <div>
    <v-container fluid class="content-wrapper pa-0">
      <v-form class="register-form" ref="form" @submit="submitForm">
        <v-row class="d-flex align-center ma-0">
          <v-col cols="12" md="12" class="px-5 pt-2">
            <BaseInput
              class="text-input mb-4"
              solo
              v-model.trim="user.firstName"
              inputLabel="First Name"
              placeholder
              name="firstName"
              :status="errors('firstName').length ? 'error': 'normal'"
              :error-messages="errors('firstName')"
              @input="$v.user.firstName.$touch()"
              @blur="$v.user.firstName.$touch()"
              :disabled="!canUpdate"
            />
            <BaseInput
              class="text-input mb-4"
              solo
              v-model.trim="user.lastName"
              inputLabel="Last Name"
              placeholder
              name="lastName"
              :status="errors('lastName').length ? 'error': 'normal'"
              :error-messages="errors('lastName')"
              @input="$v.user.lastName.$touch()"
              @blur="$v.user.lastName.$touch()"
              :disabled="!canUpdate"
            />
            <BaseInput
              class="text-input mb-4"
              solo
              inputLabel="Email"
              name="email"
              v-model.trim="user.email"
              :status="errors('email').length ? 'error': 'normal'"
              :error-messages="errors('email')"
              @input="$v.user.email.$touch()"
              @blur="$v.user.email.$touch()"
              :disabled="!canUpdate"
            />
            <BaseInput
              class="text-input mb-4"
              solo
              type="tel"
              v-mask="'###-###-####'"
              v-model.trim="user.number"
              inputLabel="Phone"
              placeholder
              name="phone"
              :error-messages="errors('number')"
              @input="$v.user.number.$touch()"
              @blur="$v.user.number.$touch()"
              :disabled="!canUpdate"
            />

            <BaseInput
              class="text-input mb-4"
              solo
              v-model.trim="user.businessName"
              :inputLabel="['agency_owner', 'agency_member'].includes(user.roleName)
                ? 'Organization Name' : 'Business Name'"
              placeholder
              name="businessName"
              :status="errors('businessName').length ? 'error': 'normal'"
              :error-messages="errors('businessName')"
              @input="$v.user.businessName.$touch()"
              @blur="$v.user.businessName.$touch()"
              :disabled="!canUpdate"
            />
            <!-- <BaseInput
              class="text-input mb-4"
              solo
              v-model.trim="user.projectName"
              inputLabel="Project Name"
              placeholder
              name="projectName"
              :status="errors('projectName').length ? 'error': 'normal'"
              :error-messages="errors('projectName')"
              @input="$v.user.projectName.$touch()"
              @blur="$v.user.projectName.$touch()"
              :disabled="!canUpdate"
            />
            <div class="date-selection type-selection mb-3">
              <label class="text-left font-label">Project Date</label>
              <date-picker
                solo
                class="date-picker"
                content-class="date-picker"
                name="date"
                no-title
                v-model="user.projectDate"
                :initialValue="user.projectDate"
                :status="errors('projectDate').length ? 'error': 'normal'"
                :error-messages="errors('projectDate')"
                @input="$v.user.projectDate.$touch()"
                @blur="$v.user.projectDate.$touch()"
              />
            </div> -->
            <label class="text-left font-label">Business Vertical</label>
            <v-select
              label="Select your business vertical"
              class="type-selection mb-n3 notranslate"
              :items="businessTypes"
              item-text="name"
              item-value="id"
              solo
              flat
              v-model="user.businessTypeId"
              :error-messages="errors('businessTypeId')"
              @input="$v.user.businessTypeId.$touch()"
              @blur="$v.user.businessTypeId.$touch()"
              :disabled="!canUpdate"
              @change="verticalUpdated = true"
              :menu-props="{'content-class' : 'notranslate'}"
            ></v-select>
            <!-- <AutoCompleteField
              class="type-selection subscription mb-n3"
              inputLabel="Subscription Blends"
              solo
              :items="filteredSubscriptionBlends"
              item-text="name"
              item-value="id"
              name="subscriptionBlendIds"
              v-model="user.subscriptionBlendIds"
              :status="errors('subscriptionBlendIds').length ? 'error': 'normal'"
              :error-messages="errors('subscriptionBlendIds')"
              @input="$v.user.subscriptionBlendIds.$touch()"
              @blur="$v.user.subscriptionBlendIds.$touch()"
              :disabled="!canUpdate"
            >
            </AutoCompleteField> -->
            <BaseInput
              class="text-input mb-4"
              solo
              v-model.trim="user.website"
              inputLabel="Website"
              placeholder
              name="website"
              :status="errors('website').length ? 'error': 'normal'"
              :error-messages="errors('website')"
              @input="$v.user.website.$touch()"
              @blur="$v.user.website.$touch()"
              :disabled="!canUpdate"
            />
            <BaseInput
              class="text-input mb-4"
              solo
              v-model.trim="user.instagram"
              inputLabel="Instagram"
              name="instagram"
              :status="errors('instagram').length ? 'error': 'normal'"
              :error-messages="errors('instagram')"
              @input="$v.user.instagram.$touch()"
              @blur="$v.user.instagram.$touch()"
              :disabled="!canUpdate"
            />
            <BaseInput
              class="text-input mb-4"
              solo
              v-model.trim="user.facebook"
              inputLabel="Facebook"
              name="facebook"
              :status="errors('facebook').length ? 'error': 'normal'"
              :error-messages="errors('facebook')"
              @input="$v.user.facebook.$touch()"
              @blur="$v.user.facebook.$touch()"
              :disabled="!canUpdate"
            />
            <BaseInput
              class="text-input mb-4"
              solo
              v-model.trim="user.linkedin"
              inputLabel="Linkedin"
              name="linkedin"
              :status="errors('linkedin').length ? 'error': 'normal'"
              :error-messages="errors('linkedin')"
              @input="$v.user.linkedin.$touch()"
              @blur="$v.user.linkedin.$touch()"
              :disabled="!canUpdate"
            />
            <BaseInput
              class="text-input mb-4"
              solo
              v-model.trim="user.youtube"
              inputLabel="Youtube"
              name="youtube"
              :status="errors('youtube').length ? 'error': 'normal'"
              :error-messages="errors('youtube')"
              @input="$v.user.youtube.$touch()"
              @blur="$v.user.youtube.$touch()"
              :disabled="!canUpdate"
            />
            <label class="text-left font-label">Content Blend & Agreements</label>
            <Upload
              id="upload-documents"
              class="upload-button text-purple"
              multiple
              mode="justImage"
              @change="uploadAgreements"
              :acceptedFiles="'.pdf'"
              v-if="canUpdate"
            >
              <template>
                <span class="ml-1 add-text h-100">Upload</span>
              </template>
            </Upload>
            <template v-for="(file, index) in user.agreements">
              <v-chip
                :key="`file-${index}`"
                :close="canUpdate"
                @click:close="deleteAgreementFile(index, file.id)"
                color="#e5e7fa"
                class="agreement-file notranslate"
                :close-icon="'mdi-close'"
              >
                {{ file.filename || file.name }}
              </v-chip>
            </template>
            <div class="date-selection type-selection">
              <label class="text-left font-label">Create Date</label>
              <date-picker
                solo
                class="date-picker"
                content-class="date-picker"
                name="date"
                no-title
                v-model="user.createdDate"
                :initialValue="user.createdDate"
                :status="errors('createdDate').length ? 'error': 'normal'"
                :error-messages="errors('createdDate')"
                @input="$v.user.createdDate.$touch()"
                @blur="$v.user.createdDate.$touch()"
                :disabled="!canUpdate"
              />
            </div>
            <div class="pt-3"></div>
            <label class="text-left pt-3 font-label">Status</label>
            <v-select
              class="type-selection mb-n3 notranslate"
              dense
              solo
              flat
              :items="statusItems"
              v-model="user.status"
              label="Inactive"
              :clearable="newUser"
              @click:clear="$nextTick(() => user.status = null)"
              :disabled="!canUpdate"
              :menu-props="{'content-class' : 'notranslate'}"
            ></v-select>
            <template v-if="!newUser">
              <label class="text-left pt-3 font-label">Role</label>
              <v-select
                class="type-selection notranslate"
                dense
                solo
                flat
                :items="roleOptions"
                v-model="user.roleName"
                :label="user.roleName"
                :clearable="newUser"
                hide-details
                :disabled="!canUpdate"
                :menu-props="{'content-class' : 'notranslate'}"
              ></v-select>
            </template>
          </v-col>
        </v-row>
        <template v-if="canUpdate">
          <v-divider></v-divider>
          <v-row class="ma-0">
            <v-col class="d-flex justify-space-between" v-if="creatingNewUser">
              <v-btn text class="ml-4"
              color="primaryGray1" @click="$emit('close-modal')">Cancel</v-btn>
              <v-btn
              :loading="loading"
              class="btn-purple mr-4"
              type="submit" @click="createUser">
                Add
              </v-btn>
            </v-col>
            <v-col class="d-flex justify-end" v-else>
              <v-btn
              :loading="loading"
              class="save-btn btn-purple"
              type="submit">{{buttonText}}</v-btn>
            </v-col>
          </v-row>
        </template>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import {
  required, email, minLength, maxLength,
} from 'vuelidate/lib/validators';
import { mask } from 'vue-the-mask';
import _ from 'lodash';
import { url } from '@/helpers';
// import EventBus from '@/helpers/event-bus';
import BaseInput from '@/components/common/BaseInput';
import DatePicker from '@/components/common/DatePicker';
import Upload from '@/components/common/Upload';
// import AutoCompleteField from '@/components/common/AutoCompleteField';

export default {
  components: {
    // AutoCompleteField,
    BaseInput,
    DatePicker,
    Upload,
  },
  directives: {
    mask,
  },
  props: {
    creatingNewUser: {
      type: Boolean,
      default: false,
    },
    userObj: {
      type: Object,
      default: () => {},
    },
    canUpdate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('profile', ['businessTypes']),
    roleOptions() {
      if (['agency_owner', 'agency_member'].includes(this.user.roleName)) {
        return this.agencyRoleOptions;
      }
      if (['client', 'member'].includes(this.user.roleName)) {
        return this.customerRoleOptions;
      }
      return [];
    },
    // filteredSubscriptionBlends() {
    //   if (this.user.businessTypeId) {
    //     const filtered = _.filter(this.subscriptionBlends,
    //       { verticalId: this.user.businessTypeId });
    //     if (this.verticalUpdated) return filtered;
    //     const legacy = !_.map(filtered, 'id').includes(this.user.subscriptionBlendIds);
    //     if (this.user.id && legacy) {
    //       return this.subscriptionBlends;
    //     }
    //     return filtered;
    //   }
    //   return this.subscriptionBlends;
    // },
    buttonText() {
      return this.newUser ? 'Add' : 'Save';
    },
    newUser() {
      return !this.userObj.id;
    },
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.user[field].$dirty) return errors;
        switch (field) {
          case 'firstName':
            if (!this.$v.user.firstName.required) {
              errors.push('Please provide your first name.');
            }
            if (!this.$v.user.firstName.maxLength) {
              errors.push('First name must be less than 50 characters length.');
            }
            break;
          case 'lastName':
            if (!this.$v.user.lastName.required) {
              errors.push('Please provide your last name.');
            }
            if (!this.$v.user.lastName.maxLength) {
              errors.push('Last name must be less than 50 characters length.');
            }
            break;
          case 'email':
            if (!this.$v.user.email.required) {
              errors.push('Please provide email address.');
            }
            if (!this.$v.user.email.email) { errors.push('Please provide a valid email address.'); }
            break;
          case 'number':
            if (!this.$v.user.number.minLength) { errors.push('Please provide valid phone number'); }
            break;
          case 'businessName':
            if (!this.$v.user.businessName.required) {
              errors.push('Please provide your business name.');
            }
            if (!this.$v.user.businessName.maxLength) {
              errors.push('Business name must be less than 50 characters length.');
            }
            break;
          // case 'subscriptionBlendIds':
          //   if (!this.$v.user.subscriptionBlendIds.required)
          //  { errors.push('Please select a subscription blend.'); }
          //   break;
          case 'website':
            if (!this.$v.user.website.required) {
              errors.push('Please provide your business website.');
            }
            if (!this.$v.user.website.url) { errors.push('Please provide valid url.'); }
            break;
          case 'businessTypeId':
            if (!this.$v.user.businessTypeId.required) {
              errors.push('Please provide your business vertical.');
            }
            break;
          case 'createdDate':
            if (!this.$v.user.createdDate.required) {
              errors.push('Please provide your create date.');
            }
            break;
          case 'instagram':
          case 'facebook':
          case 'linkedin':
          case 'youtube':
            if (!this.$v.user[field].url) { errors.push('Please provide valid url.'); }
            break;
          // case 'status':
          //   if (!this.$v.user.status.required) {
          //     errors.push('Please provide your status.');
          //   }
          //   break;
          default:
            break;
        }
        return errors;
      };
    },
  },
  methods: {
    ...mapActions('admin', ['registerUser', 'getUserDetails', 'updateUser']),
    ...mapActions('project', ['getSubscriptionBlends']),
    deleteAgreementFile(index, id) {
      this.user.agreements.splice(index, 1);
      if (id) {
        this.user.deletedAgreementFileIds.push(id);
      }
    },
    async submitForm(e) {
      e.preventDefault();
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        // const user = { ...this.user, subscriptionBlendIds: [this.user.subscriptionBlendIds] };
        const user = { ...this.user };
        const result = this.user.id
          ? await this.updateUser(user)
          : await this.registerUser(user);
        if (result.success) {
          this.$emit('close', true);
        }
        this.loading = false;
      }
    },
    uploadAgreements(files) {
      if (files && files.length) {
        _.forEach(files, (file) => {
          this.user.agreements.push(file);
        });
      }
    },
  },
  validations: {
    user: {
      firstName: {
        required,
        maxLength: maxLength(50),
      },
      lastName: {
        required,
        maxLength: maxLength(50),
      },
      email: {
        required,
        email,
      },
      number: {
        minLength: minLength(12),
      },
      businessName: {
        required,
        maxLength: maxLength(50),
      },
      website: {
        required,
        url,
      },
      businessTypeId: {
        required,
      },
      // projectName: {
      //   required,
      //   maxLength: maxLength(50),
      // },
      // projectDate: {
      //   required,
      // },
      // subscriptionBlendIds: {
      //   required,
      // },
      createdDate: {
        required,
      },
      instagram: {
        url,
      },
      facebook: {
        url,
      },
      linkedin: {
        url,
      },
      youtube: {
        url,
      },
    },
  },
  data() {
    return {
      user: {
        firstName: '',
        lastName: '',
        email: '',
        number: null,
        businessName: '',
        website: null,
        facebook: null,
        instagram: null,
        linkedin: null,
        youtube: null,
        businessTypeId: null,
        createdDate: new Date().toISOString().substr(0, 10),
        // projectDate: new Date().toISOString().substr(0, 10),
        // projectName: '',
        // subscriptionBlendIds: [],
        role: 'client',
        roleName: '',
        status: null,
        deletedAgreementFileIds: [],
        agreements: [],
      },
      customerRoleOptions: [
        { text: 'Owner', value: 'client' },
        { text: 'Member', value: 'member' },
      ],
      agencyRoleOptions: [
        { text: 'Organization Owner', value: 'agency_owner' },
        { text: 'Organization Member', value: 'agency_member' },
      ],
      statusItems: [
        { text: 'Active', value: '1' },
        { text: 'Blocked', value: '3', disabled: !this.$can('delete', 'client management') },
      ],
      loading: false,
      subscriptionBlends: [],
      verticalUpdated: false,
    };
  },
  async beforeMount() {
    const result = await this.getSubscriptionBlends();
    this.subscriptionBlends = result.subscriptionBlends;
  },
  mounted() {
    if (this.userObj.id) {
      const userObj = _.omit(this.userObj, ['projectName', 'projectDate']);
      userObj.createdDate = _.get(userObj, 'createdDate') || moment().format('YYYY-MM-DD');
      this.user = Object.assign(this.user, userObj);
    }
  },
};
</script>

<style lang="scss" scoped>
.upload-field {
  margin-bottom: 30px !important;
}
::v-deep .v-input__prepend-outer {
  display: none;
}
::v-deep .v-input__prepend-outer {
  visibility: hidden !important;
}
.save-btn {
  width: 140px;
  height: 40px;
}
.upload-text {
  font-family: $fontFamily1;
  font-size: 12px;
  letter-spacing: -0.01px;
  color: #00b0d1;
}
.delete-btn {
  width: 74px;
  height: 17px;
  font-family: $fontFamily1;
  font-size: 14px;
  color: #ff0808;
}
.upload-text:hover {
  cursor: pointer;
}
::v-deep .mdi .mdi-paperclip {
  display: none !important;
}
::v-deep .type-selection .v-input__control > .v-input__slot {
  padding-left: 0 !important;
  padding-right: 0 !important;
  box-shadow: none !important;
}
::v-deep .type-selection.subscription .v-input__control > .v-input__slot {
  border: none !important;
}
::v-deep .date-selection.type-selection .v-input__slot {
  padding-left: 12px !important;
  padding-right: 0 !important;
  box-shadow: none !important;
  border-color: $silver !important;
}
::v-deep .v-label {
  padding-left: 5px !important;
}
.form-heading {
  font-family: $fontFamily1;
  font-size: 16px;
  font-weight: bold;
}
::v-deep.text-input > .input-field > .v-input__control > .v-input__slot {
  box-shadow: none;
  border-radius: 2px;
  border: solid 1px $silver;
  background-color: #ffffff;
}

::v-deep .font-label {
  font-family: $fontFamily1;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.02px;
  color: $charcoalBlack;
}
::v-deep .v-select__slot {
  min-width: 240px;
  border: 1px solid $silver;
  border-radius: 6px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.07);
  padding-left: 10px;
}
.client-tag-form {
  ::v-deep .font-label {
    font-family: $fontFamily1;
    font-weight: bold;
  }
  ::v-deep .input-field {
    margin: 0;
    padding: 0;
    margin-bottom: 10px !important;
  }
  ::v-deep .v-input--radio-group.v-input--radio-group--row .v-radio {
    margin-right: 0;
  }
}

.date-selection {
  ::v-deep  .v-text-field {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }
  ::v-deep .v-input__slot {
    border: 1px solid $silver;
    border-bottom-color: $silver;
    padding-left: 10px;
  }
}
    ::v-deep .v-list-item__title {
      font-family: $fontFamily1;
      font-size: 14px !important;
      line-height: 1.2 !important;
    }
    .upload-button {
      font-family: $fontFamily1;
      font-size: 12px;
      letter-spacing: -0.01px;
      color: $secondary1;
      span {
        cursor: pointer !important;
      }
    }
    .agreement-file {
      color: $primary2;
      margin: 4px;
      font-size: 14px !important;
      border-radius: 6px;
      font-family: $fontFamily1;
    }
</style>
