<template>
  <div>
    <div class="content-wrapper" h-100>
      <v-row class="ma-0 justify-space-between align-center mb-12">
        <PageTitle />
        <v-btn
          class="btn-purple add-button"
          @click="openForm"
          v-if="canAddUser()
          && this.selectedUserRole !== 'admin' && this.selectedUserRole !== 'client'"
        ><v-icon class="mr-2">mdi-plus</v-icon> Add User</v-btn>
      </v-row>
      <v-row class="ma-0">
        <v-col class="pa-0 d-flex align-center" lg=5>
          <v-select
            solo
            flat
            :items="userRolesList"
            :value="userRolesList[0]"
            append-icon="mdi-chevron-down"
            @change="emitSelectUserList"
            :menu-props="{'content-class' : 'admin-dropdown'}"
            class="roles-dropdown"
            hide-details
          ></v-select>
          <div class="d-flex pt-0 mb-0 pb-0 export-button align-center"
            v-if="this.selectedUserRole !== 'admin' && this.exportData.length && !this.loading">
            <v-btn text @click="downloadCSV(csvData)" color="info"
              v-if="this.isFiltered"
              >
              <!-- || this.clearvalue -->
              Export Filtered Users
            </v-btn>
            <v-btn text @click="downloadCSV(csvData)" color="info"
              v-else>
              Export All Users
            </v-btn>
          </div>
        </v-col>
        <!-- <v-col cols="3" class="pa-0 d-flex align-center justify-end">
        </v-col> -->
        <v-col class="d-flex align-center justify-end pa-0">
          <BaseInput
            solo
            hide-details
            v-model="search"
            clearable
            @click:clear="search === ''"
            placeholder="Search ..."
            prepend-inner-icon="search"
            class="mt-1 search-box"
          />
        </v-col>
      </v-row>
      <div class="listing-block">
        <Loader v-if="loading" />
        <UserList
          v-else
          :users="users"
          :fetching="loading"
          :selectedRole="selectedUserRole"
          @onEditClick="openEditModal"
          @onDeleteClick="openDeleteModal"
          @nameClicked="viewProfile"
          @setDisplayedRows="setRowsData"
          :search="search"
        />
        <!-- @cleargetvalue="onclearValue" -->
      </div>
    </div>
    <v-container
      fluid
      v-if="showUserForm"
    >
      <Modal
        persistent
        :fullscreen="$vuetify.breakpoint.xsOnly"
        content-class="new-user-form"
        :modal="showUserForm"
        scrollable
      >
        <UserForm
          :user="selectedUser"
          :role="this.selectedUserRole"
          @close-modal="closeForm"
        />
      </Modal>
    </v-container>
    <view-profile-modal
    :profilePic="profilePic(selectedMember)"
    :imageBackground="checkProfile(selectedMember.profilePic)"
      :name="`${selectedMember.firstName || '' } ${ selectedMember.lastName || '' }`"
    :skillset="creativeSkillsets(selectedMember)"
    :viewProfileModal="viewProfileModal"
    :selectedMember="selectedMember"
    @setViewProfileModal="viewProfileModal=false"
    />
     <template v-if="showDeleteConfirm">
      <v-dialog v-model="showDeleteConfirm" max-width="600">
        <v-card class="pa-3" flat>
          <div class="d-flex justify-end">
            <v-icon class="pa-2 pb-1 icon-height" @click="showDeleteConfirm = false">
              mdi-close
            </v-icon>
            </div>
            <v-card-text class="modal-heading pl-2 pb-1" v-if="selectedUser.deletedAt === null">
              Are you sure, you want to delete the user
              <span class="project-name">
                {{ `${selectedUser.firstName} ${selectedUser.lastName}` }}
              </span>?
            </v-card-text>
            <v-card-text v-else class="modal-heading pl-2 pb-1">
              Are you sure, you want to reactivate the user
              <span class="project-name">
                {{ `${selectedUser.firstName} ${selectedUser.lastName}` }}
              </span>?
            </v-card-text>
            <v-row class="ma-0 pa-4 py-3">
              <v-col cols="12" class="d-flex justify-end pa-0">
                  <v-btn text
                    @click="showDeleteConfirm = false"
                    class="pa-0 cancel-btn"
                  >
                    CANCEL
                  </v-btn>
                  <v-btn text
                    type="submit"
                    class="ml-4 pa-0 btn-purple"
                    @click="removeUser(selectedUser.id)"
                  >
                    <span v-if="selectedUser.deletedAt === null">DELETE</span>
                    <span v-else>REACTIVATE</span>
                  </v-btn>
              </v-col>
            </v-row>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>
<script>
/* eslint-disable global-require */
import { mapActions, mapGetters } from 'vuex';
import vClickOutside from 'v-click-outside';
import _ from 'lodash';
import moment from 'moment';
// import EventBus from '@/helpers/event-bus';
import BaseInput from '@/components/common/BaseInput';
import Modal from '@/components/common/Modal';
import UserForm from '@/components/dashboard/screens/Admin/Forms/UserForm';
import UserList from '@/components/admin/UserList';
import ViewProfileModal from '@/components/common/ViewProfileModal';
import Loader from '@/components/common/Loader';
import PageTitle from '@/components/common/PageTitle';

// import roleBasedDataTable from './roleBasedDataTable';
export default {
  components: {
    BaseInput,
    // roleBasedDataTable,
    Modal,
    UserForm,
    UserList,
    ViewProfileModal,
    Loader,
    PageTitle,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },

  watch: {
    selectedUserRole(val) {
      this.resetFilters();
      this.getUsers(val);
    },
  },

  computed: {
    ...mapGetters('profile', { filters: 'creativePreferences' }),
    ...mapGetters('profile', ['businessTypes']),
    ...mapGetters('admin', ['selectedUserRole']),
    getUserIds() {
      return _.map(this.exportData, 'data.id');
    },
    isFiltered() {
      return this.exportData.length !== this.users.length;
    },
    creativeSkillsets() {
      return (member) => {
        const skillsets = _.get(member, 'creativeProfile.skillsets', []);
        return skillsets.map((x) => (x.name === 'Other' ? x.skillSetName : x.name)).join(', ');
      };
    },
    selectedRole() {
      switch (this.selectedUserRole) {
        case 'creative':
          return 'Creatives';
        case 'client':
          return 'Customers';
        case 'producer':
          return 'Producers';
        default:
          return 'Admins';
      }
    },
    userRolesList() {
      const items = [];
      if (this.$can('read', 'admin management')) {
        items.push({ text: 'Admins', value: 'admin' });
      }
      if (this.$can('read', 'creative management')) {
        items.push({ text: 'Creatives', value: 'creative' });
      }
      if (this.$can('read', 'client management')) {
        items.push({ text: 'Customers', value: 'client' });
      }
      if (this.$can('read', 'producer management')) {
        items.push({ text: 'Operations', value: 'producer' });
      }
      return items;
    },
  },

  methods: {
    ...mapActions('user', ['fetchAdminUsersList']),
    ...mapActions('profile', ['getCreativeOptions', 'getClientOptions']),
    ...mapActions('admin', ['getUserDetails', 'setUserRole', 'getExportUsers', 'getPayTiers', 'deleteUser', 'reactivateUser']),
    // checkRole() {
    //   console.log('jflsdfsfsda', this.selectedUserRole);
    // },
    // onclearValue(value) {
    //   console.log('ffsa', value);
    //   console.log('get', value.filter === this.users.map((x) => x.firstName));
    //   this.clearvalue = value;
    //   console.log(this.clearvalue || this.clearvalue.conditiona2.filter);
    //   if (this.clearvalue === this.users.map((x) => x.firstName)
    //   || this.clearvalue.conditiona2.filter === this.users.map((x) => x.firstName)) {
    //     return this.clearvalue || this.clearvalue.conditiona2.filter;
    //   }
    //   return this.clearvalue || this.clearvalue.conditiona2.filter;
    // },
    setRowsData(data) {
      this.exportData = data;
    },
    checkProfile(pic) {
      if (pic) {
        return false;
      }
      return true;
    },
    profilePic(member) {
      const profilePic = _.get(member, 'profilePic');
      if (profilePic) {
        return profilePic;
      }
      return require('@/assets/svg/users.svg');
    },
    canAddUser() {
      return this.$can('create', `${this.selectedUserRole} management`);
    },
    async emitSelectUserList(item) {
      this.users = [];
      this.setUserRole(item);
      const payload = {
        roleName: item,
        skillIds: this.selectedSkillsets,
        status: this.getSelectedStatus(),
        segmentIds: this.segmentIds,
        sortBy: this.sortBy,
        orderBy: this.orderBy,
      };
      const { users } = await this.fetchAdminUsersList(payload);
      this.users = users;
      this.loading = false;
    },
    getRole(role) {
      switch (role) {
        case 'executive':
          return 'Production Manager';
        case 'producer':
          return 'Content Producer';
        default:
          return _.startCase(role);
      }
    },
    getAvailability(availability) {
      switch (availability) {
        case '1':
          return 'Available';
        default:
          return 'Unavailable';
      }
    },
    getStatus(status) {
      switch (status) {
        case '0':
          return 'Inactive';
        case '2':
          return 'Limited';
        case '3':
          return 'Blocked';
        case '4':
          return 'Rejected';
        default:
          return 'Active';
      }
    },
    skillsetNames(skillsets) {
      return skillsets.map((x) => (x.name === 'Other' ? x.skillSetName : x.name)).join(', ');
    },
    csvData(users) {
      switch (this.selectedUserRole) {
        case 'client':
          return _.map(users, (user, i) => ({
            // const project = _.get(user.business, 'projects[0]', {});
            // const blends = _.map(_.get(project, 'subscriptionBlends', []), 'name');
            // return ({
            // clearvalue: this.onclearValue(user),
            sno: i + 1,
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
            phone: user.number,
            'business vertical': _.get(user.business, 'businessType.shortName', ''),
            // 'Subscription Blends': blends.join(', '),
            business: _.get(user.business, 'name'),
            instagram: _.get(user.business, 'instagram'),
            facebook: _.get(user.business, 'facebook'),
            linkedin: _.get(user.business, 'linkedin'),
            youtube: _.get(user.business, 'youtube'),
            'content blend & agreements': _.map(user.agreements, 'file').join(', '),
            'created date': user.createdDate ? moment(user.createdDate).format('M/D/YY') : '',
            website: _.get(user.business, 'website'),
            role: _.get(user.role, 'name'),
            status: this.getStatus(user.status),
          }));
        case 'creative':
          return _.map(users, (user, i) => ({
            sno: i + 1,
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
            projects: user.activeProjectsCount || 0,
            phone: user.number,
            radius: user.radius,
            'skill set': user.skillsets,
            'created date': user.createdDate ? moment(user.createdDate).format('M/D/YY') : '',
            instagram: user.instagramUrl,
            facebook: user.facebookUrl,
            'slack id': user.slackId,
            website: _.get(user.creativeProfile, 'portfolio'),
            location: user.location,
            'zip code': user.zipcode,
            'camera equipment': user.camera,
            'lens equipment': user.lens,
            accessories: user.accessories,
            'interest tags': user.interests,
            styles: user.styles,
            'writer subjects': user.subjects,
            'writer interests': user.writerInterests,
            'handbooks & agreements': _.map(user.agreements, 'file').join(', '),
            status: this.getStatus(user.status),
            reason: user.reason,
            availability: this.getAvailability(user.availability),
          }));
        default:
          return _.map(users, (user, i) => ({
            sno: i + 1,
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
            phone: user.number,
            segment: _.map(_.get(user.producerProfile, 'businessTypes', []), 'name').join(', '),
            role: this.getRole(_.get(user, 'role.name', '')),
            status: this.getStatus(user.status),
          }));
      }
    },

    async downloadCSV() {
      this.showLoader = true;
      const payload = {
        role: this.selectedUserRole,
        skillIds: this.selectedSkillsets,
        status: this.getSelectedStatus(),
        // clearvalueBy: this.clearvalue,
        segmentIds: this.segmentIds,
        searchBy: this.search,
        sortBy: this.sortBy || 'firstName',
        orderBy: this.orderBy,
        perPage: 'ALL',
        ids: this.getUserIds,
      };
      const result = await this.getExportUsers(payload);
      if (result.success) {
        const arrData = this.csvData(result.users);
        const csvContent = [
          _.map(Object.keys(arrData[0]), (key) => key.toUpperCase()).join(';'),
          ...arrData.map((item) => Object.values(item).join(';')),
        ]
          .join('\n')
          .replace(/(^\[)|(\]$)/gm, '');
        const blob = new Blob([csvContent], {
          type: 'text/csv;charset=utf-8',
        });
        const url = window.URL.createObjectURL(blob);
        // const data = encodeURI(csvContent);
        const link = document.createElement('a');
        const fileName = _.find(this.userRolesList,
          (id) => id.value === this.selectedUserRole).text;
        link.setAttribute('href', url);
        link.setAttribute('download', `${fileName.toLowerCase()}.csv`);
        link.click();
      }
      this.showLoader = false;
    },
    async closeForm(success = false) {
      this.showUserForm = false;
      this.selectedUser = {};
      if (success) {
        await this.fetchUsers();
      }
    },
    resetFilters() {
      this.selectedSkillsets = [];
      this.selectedOption = 'Name';
      this.selectedStatus = [];
      this.sortBy = '';
      this.orderBy = '';
      this.search = '';
    },

    async runSearch() {
      this.fetchUsers();
    },
    async applyFilters() {
      this.fetchUsers();
    },
    async sortList() {
      switch (this.selectedOption) {
        case 'new':
          this.sortBy = 'createdDate';
          this.orderBy = 'Desc';
          break;
        case 'old':
          this.sortBy = 'createdDate';
          this.orderBy = 'Asc';
          break;
        default:
          this.sortBy = 'firstName';
          this.orderBy = 'Asc';
      }
      this.fetchUsers();
    },
    async openEditModal(id, producer = false) {
      this.showLoader = true;
      this.selectedUser = producer ? _.find(this.users, { id }) : await this.getUserDetails(id);
      this.creatingNewUser = false;
      this.showUserForm = true;
      this.showLoader = false;
    },

    async openDeleteModal(id) {
      this.selectedUser = _.find(this.users, { id });
      this.showDeleteConfirm = true;
    },

    async viewProfile(data) {
      const { id } = data;
      this.selectedMember = _.find(this.users, { id });
      this.viewProfileModal = true;
    },

    async removeUser(id) {
      const res = this.selectedUser.deletedAt === null ? await this.deleteUser(id)
        : await this.reactivateUser(id);
      if (res.success) {
        this.fetchUsers();
        this.showDeleteConfirm = false;
      }
    },

    async fetchUsers() {
      this.loading = true;
      const payload = {
        roleName: this.selectedUserRole,
        skillIds: this.selectedSkillsets,
        status: this.getSelectedStatus(),
        segmentIds: this.segmentIds,
        sortBy: this.sortBy,
        orderBy: this.orderBy,
      };
      const { users } = await this.fetchAdminUsersList(payload);
      this.users = users;
      this.loading = false;
    },

    async getUsers(role) {
      this.loading = true;
      const { users } = await this.fetchAdminUsersList({ roleName: role });
      this.users = users;
      this.loading = false;
    },
    openForm() {
      this.creatingNewUser = true;
      this.showUserForm = true;
    },
    getSelectedStatus() {
      if (this.selectedStatus.length === 1) {
        return this.selectedStatus[0];
      } return '';
    },
    onClickOutsideFilter() {
      this.cardVisibility = false;
    },
  },
  async mounted() {
    this.loading = true;
    this.getCreativeOptions();
    this.getClientOptions();
    if (this.userRolesList[0]) {
      this.setUserRole(this.userRolesList[0].value);
    }
    this.getPayTiers();
    this.loading = false;
  },
  beforeDestroy() {
    this.setUserRole('');
  },
  data() {
    return {
      showUserForm: false,
      search: '',
      // clearvalue: '',
      status: '',
      cardVisibility: false,
      loading: false,
      users: [],
      userSelectedRole: '',
      selectedSkillsets: [],
      selectedOption: 'Name',
      segmentIds: [],
      selectedStatus: [],
      sortBy: '',
      orderBy: '',
      creatingNewUser: false,
      editing: false,
      selectedUser: {},
      showLoader: false,
      showDeleteConfirm: false,
      selectedMember: {},
      viewProfileModal: false,
      exportData: [],
    };
  },
};
</script>

<style scoped lang="scss">
::v-deep.theme--light.v-select .v-select__selection--comma{
  overflow: visible;
}
.padding-right{
  padding-right:60px !important;
}
.heading-text {
  font-family: $fontFamily1;
  font-size: 24px;
  font-weight: normal !important;
}
.sort-dropdown {
  max-width: 30%;
}
.roles-dropdown {
  max-width: min-content;
  ::v-deep &.v-text-field {
    font-size: 16px;
    overflow: visible;
  }
}
.roles-dropdown, .sort-dropdown {
  ::v-deep .v-input__slot {
    background: transparent !important;
  }
}
::v-deep .v-label {
  font-family: $fontFamily1;
  font-size: 14px;
  color: $neutral1;
}
.sort-text {
  font-family: $fontFamily1;
  font-size: 14px;
}
::v-deep .v-text-field {
  font-family: $fontFamily1;
  font-size: 14px;
}
.search-box ::v-deep {
  .v-input__slot {
    border: 1px solid  #b0b0b0 !important;
    box-shadow: none !important;
    border-radius: 0 !important;
  }
  .v-input__control {
    input {
      max-height: 34px !important;
    };
  }
}
.heading {
  .text {
    font-size: 24px;
    color: $neutral1;
    text-transform: capitalize;
  }
}
.add-button, .export-button {
  ::v-deep .v-btn__content {
    font-family: $fontFamily1;
    font-size: 16px;
    letter-spacing: -0.32px;
  }
}
.search-box {
  ::v-deep .v-text-field {
    height: 40px;
    font-size: 16px;
  }
}

.filter-box {
  width: 90%;
  border:1px solid  #b0b0b0 !important;
  border-radius: 0 !important;
  color: black;
  cursor: pointer;
  max-height: 36px;
  font-family: $fontFamily1;
  font-size: 16px;
}
.v-data-table {
  ::v-deep th {
    font-weight: bold;
    font-size: 16px;
    color: $neutral1 !important;
  }
  ::v-deep td {
    font-size: 14px;
    color: $neutral1;
    font-family: $fontFamily1;
  }
  ::v-deep td:nth-child(1) {
    color: #0373d1;
  }
  ::v-deep tr:nth-child(even) {
    background-color: #f8f8f8;
  }
}
.heading {
  font-family: $fontFamily1;
  font-size: 14px;
  font-weight: bold;
  color: $neutral1;
}
.filter-wrapper {
  background-color: #f8f8f8;
  border: 1px solid #dddddd;
}
.filter-heading,
::v-deep .input-field-label {
  font-family: $fontFamily1;
  font-size: 14px;
  color: #404040;
}
.filter-item {
  padding: 12px 20px 0;
  // flex-basis: 25%;
}
.filter-checkbox {
  ::v-deep {
    .v-icon {
      background-color: white !important;
      border-radius: 6px;
      border: 2px solid #cfd5dd !important;
      &.mdi-check {
        border: 2px solid $primary1 !important;
        font-size: 16px;
      }
    }
  }
}
.modal-card {
  position: absolute;
  right: 0px;
  width:500px;
  font-family: $fontFamily1;
  font-size: 14px;
  top: 50px;
  z-index: 10;
  .card-list {
    background-color: #f8f8f8;
  }
}

.listing-block {
  height: calc(100vh - 150px);
  overflow: auto;
}
.modal-heading {
  font-family: $fontFamily1;
  font-size: 16px;
  font-weight: normal;
  text-align: left;
  color: #1b1c1e !important;
}
.cancel-btn {
  font-family: $fontFamily1;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #9b9b9b;
}
.project-name {
  font-weight: bold;
  text-transform: uppercase
}
@media (min-width: 600px) {
  .border-right {
    border-right: 1px solid #dddddd;
  }
}
@media (max-width: 600px) {
  .listing-block {
    height: calc(100vh - 210px);
  }
}
</style>
