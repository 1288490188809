import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"flat":""}},[_c('div',{staticClass:"px-5"},[_c(VRow,{staticClass:"my-0"},[_c(VCol,{staticClass:"d-flex align-center pb-1",attrs:{"cols":"10"}},[_c('span',{staticClass:"modal-header-title mr-1"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c(VCol,{staticClass:"d-flex justify-end pb-1"},[_c(VIcon,{attrs:{"color":"darken-1"},on:{"click":function($event){return _vm.$emit('close-modal')}}},[_vm._v("mdi-close")])],1)],1)],1),_c(VDivider),_c(VCardText,{staticClass:"form-body pa-0 pt-2"},[(this.role === 'creative')?[_c('creative-form',{attrs:{"userObj":_vm.creativeUser,"canUpdate":_vm.canUpdate},on:{"close":_vm.closeForm}})]:(this.role === 'producer')?[_c('div',{staticClass:"pa-4"},[_c('ProducerForm',{attrs:{"userObj":_vm.producerUser,"canUpdate":_vm.canUpdate},on:{"close":_vm.closeForm}})],1)]:[_c('client-form',{attrs:{"userObj":_vm.clientUser,"canUpdate":_vm.canUpdate},on:{"close":_vm.closeForm}})]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }