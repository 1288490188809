<template>
  <div>
    <v-container fluid class="content-wrapper pa-0">
      <v-form class="register-form" ref="form" @submit="submitForm">
        <v-row class="d-flex align-center ma-0 px-5">
          <v-col cols="12" md="12" class="pa-0 pt-2">
            <BaseInput
              class="text-input mb-4"
              solo
              v-model.trim="user.firstName"
              inputLabel="First Name"
              placeholder
              name="firstName"
              :status="errors('firstName').length ? 'error': 'normal'"
              :error-messages="errors('firstName')"
              @input="$v.user.firstName.$touch()"
              @blur="$v.user.firstName.$touch()"
              :disabled="!canUpdate"
            />
          </v-col>
          <v-col cols="12" md="12" class="pa-0">
            <BaseInput
              class="text-input mb-4"
              solo
              v-model.trim="user.lastName"
              inputLabel="Last Name"
              placeholder
              name="lastName"
              :status="errors('lastName').length ? 'error': 'normal'"
              :error-messages="errors('lastName')"
              @input="$v.user.lastName.$touch()"
              @blur="$v.user.lastName.$touch()"
              :disabled="!canUpdate"
            />
          </v-col>
          <v-col cols="12" md="12" class="pa-0">
            <BaseInput
              class="text-input mb-4"
              solo
              inputLabel="Email"
              name="email"
              v-model.trim="user.email"
              :status="errors('email').length ? 'error': 'normal'"
              :error-messages="errors('email')"
              @input="$v.user.email.$touch()"
              @blur="$v.user.email.$touch()"
              :disabled="!canUpdate"
            />
          </v-col>
          <v-col cols="12" md="12" class="pa-0">
            <BaseInput
              class="text-input mb-4"
              solo
              type="tel"
              v-mask="'###-###-####'"
              v-model.trim="user.number"
              inputLabel="Phone"
              placeholder
              name="phone"
              :error-messages="errors('number')"
              @input="$v.user.number.$touch()"
              @blur="$v.user.number.$touch()"
              :disabled="!canUpdate"
            />
          </v-col>
          <v-row class="pt-0 mt-0">
            <v-col
              style="max-width: 30%;"
              cols="4"
              class="d-flex mt-0 pt-0 pr-0 mr-0 align-center">
                <label class="text-left font-label">
                  Radius (Miles)
                </label>
            </v-col>
            <v-col sm="8" class="mt-0 pl-0 ml-3 pr-0">
              <div>
                <v-slider
                  :key="user.radius"
                  :value="user.radius"
                  @change="(radius) => setRadius(radius)"
                  :thumb-size="24"
                  max="80"
                  min="5"
                  thumb-label="always"
                ></v-slider>
              </div>
            </v-col>
          </v-row>
          <v-col cols="12" md="12" class="pa-0 type-selection">
            <AutoCompleteField
              inputLabel="Skill Set"
              solo
              multiple
              :items="skillsets"
              item-text="name"
              return-object
              name="skillsets"
              v-model="user.skillsets"
              :status="errors('skillsets').length ? 'error': 'normal'"
              :error-messages="errors('skillsets')"
              @input="$v.user.skillsets.$touch()"
              @blur="$v.user.skillsets.$touch()"
              :disabled="!canUpdate"
            >
            </AutoCompleteField>
          </v-col>
          <v-col cols="9"
            v-for="(skill, j) in user.skillsets"
            :key="`skill${j}`"
            class="pa-0"
          >
          <div
            v-if="showTierSelection(skill)"
            class="d-flex align-center justify-start pb-2"
          >
            <v-chip
              color="#e5e7fa"
              class="skill-chip notranslate"
              label
            >
              {{ skill.name }}
            </v-chip>
            <v-select
              class="mx-3 tier-selection"
              :class="{talent: skill.name === 'Talent'}"
              :items="tierOptions(skill)"
              :multiple="skill.name === 'Talent'"
              v-model="skill.tier"
              outlined
              dense
              flat
              hide-details=""
              auto-grow
              :menu-props="{
                'content-class' : 'tier-selection-dropdown talent-skills-dropdown notranslate'}"
              style="min-width: min-content;"
            >
            </v-select>
          </div>
          </v-col>
          <v-col cols="12" md="12" class="pa-0" v-if="hasOtherSkillset()">
            <BaseInput
              inputLabel="Other Skill Set"
              class="text-input mb-4"
              solo
              name="otherSkillset"
              v-model.trim="user.otherSkillset"
              @input="updateOtherSkillName"
              :status="errors('otherSkillset').length ? 'error': 'normal'"
              :error-messages="errors('otherSkillset')"
              @blur="$v.user.otherSkillset.$touch()"
              :disabled="!canUpdate"
            />
          </v-col>
          <v-col cols="12" md="12" class="pa-0">
            <div> <label class="text-left font-label">Handbooks & Agreements</label> </div>
            <Upload
              id="upload-documents"
              class="upload-button"
              multiple
              mode="justImage"
              @change="uploadAgreements"
              :acceptedFiles="'.pdf'"
              v-if="canUpdate"
            >
              <template>
                <span class="ml-1 add-text h-100">Upload</span>
              </template>
            </Upload>
            <template v-for="(file, index) in user.agreements">
              <v-chip
                :key="`file-${index}`"
                :close="canUpdate"
                @click:close="deleteAgreementFile(index, file.id)"
                color="#e5e7fa"
                class="agreement-file notranslate"
                :close-icon="'mdi-close'"
              >
                {{ file.filename || file.name }}
              </v-chip>
            </template>
          </v-col>
          <v-col cols="12" md="12" class="pa-0">
            <BaseInput
              class="text-input mb-4"
              solo
              v-model.trim="user.instagramUrl"
              inputLabel="Instagram"
              name="instagram"
              :status="errors('instagramUrl').length ? 'error': 'normal'"
              :error-messages="errors('instagramUrl')"
              @input="$v.user.instagramUrl.$touch()"
              @blur="$v.user.instagramUrl.$touch()"
              :disabled="!canUpdate"
            />
          </v-col>
          <v-col cols="12" md="12" class="pa-0">
            <BaseInput
              class="text-input mb-4"
              solo
              v-model.trim="user.facebookUrl"
              inputLabel="Facebook"
              name="facebook"
              :status="errors('facebookUrl').length ? 'error': 'normal'"
              :error-messages="errors('facebookUrl')"
              @input="$v.user.facebookUrl.$touch()"
              @blur="$v.user.facebookUrl.$touch()"
              :disabled="!canUpdate"
            />
          </v-col>
          <v-col cols="12" md="12" class="pa-0">
            <BaseInput
              class="text-input mb-4"
              solo
              v-model.trim="user.slackId"
              inputLabel="Slack ID"
              name="slack"
              :disabled="!canUpdate"
            />
          </v-col>
          <v-col cols="12" md="12" class="pa-0">
            <BaseInput
              class="text-input mb-4"
              solo
              v-model.trim="user.portfolio"
              inputLabel="Website"
              name="portfolio"
              :status="errors('portfolio').length ? 'error': 'normal'"
              :error-messages="errors('portfolio')"
              @input="$v.user.portfolio.$touch()"
              @blur="$v.user.portfolio.$touch()"
              :disabled="!canUpdate"
            />
          </v-col>
          <v-col cols="12" md="12" class="pa-0">
            <div class="date-selection type-selection">
              <label class="text-left font-label">Create Date</label>
              <date-picker
                solo
                class="date-picker"
                content-class="date-picker"
                name="date"
                no-title
                v-model="user.createdDate"
                :initialValue="user.createdDate"
                :status="errors('createdDate').length ? 'error': 'normal'"
                :error-messages="errors('createdDate')"
                @input="$v.user.createdDate.$touch()"
                @blur="$v.user.createdDate.$touch()"
                :disabled="!canUpdate" />
            </div>
          </v-col>
          <v-col cols="12" md="12" class="pa-0">
            <BaseInput
              class="text-input mb-4 pt-3"
              solo
              v-model.trim="user.location"
              inputLabel="Location"
              name="location"
              :disabled="!canUpdate"
            />
          </v-col>
          <v-col cols="12" md="12" class="pa-0">
            <BaseInput
              class="text-input mb-3"
              solo
              inputLabel="Zip Code"
              name="zipcode"
              v-model.trim="user.zipcode"
              :status="errors('zipcode').length ? 'error': 'normal'"
              :error-messages="errors('zipcode')"
              @input="$v.user.zipcode.$touch()"
              @blur="$v.user.zipcode.$touch()"
              :disabled="!canUpdate"
            />
          </v-col>
          <v-col v-for="(tag, i) in tags" :key="`tag${i}`"
            class="pa-0 tags type-selection" cols="12">
            <CustomComboBox
              v-if="tag.type === 'combo'"
              solo
              :label="tag.title"
              name="tag.name"
              v-model="user[tag.name]"
              multiple
              :items="tag.items"
              item-text="name"
              item-value="id"
              hide-details
              :disabled="!canUpdate"
              :notClearable="!canUpdate"
            >
              <v-chip
                color="#e5e7fa"
                label
                v-for="(set, j) in tag.values"
                :key="`${tag.title}-${j}`"
                class="notranslate"
              >
                  {{ set.name }}
              </v-chip>
            </CustomComboBox>
            <AutoCompleteField
              v-else
              solo
              :inputLabel="tag.title"
              :mode="'edit'"
              multiple
              :items="tag.items"
              item-text="name"
              item-value="id"
              :name="tag.name"
              v-model="user[tag.name]"
              hide-details
              :disabled="!canUpdate"
              :notClearable="!canUpdate"
            >
              <v-chip
                color="#e5e7fa"
                label
                v-for="(id, j) in tag.values"
                :key="`${tag.title}-${j}`"
                class="notranslate"
              >
                {{ getText(id, tag.items, 'name') }}
              </v-chip>
            </AutoCompleteField>
          </v-col>
          <v-col cols="12" md="12" class="pa-0 mb-2">
            <label class="text-left font-label">Status</label>
            <v-select
              class="type-selection notranslate"
              solo
              :items="statusItems"
              v-model="user.status"
              label="Inactive"
              :clearable="newUser"
              @click:clear="$nextTick(() => user.status = null)"
              :disabled="!canUpdate"
              hide-details
              :menu-props="{'content-class' : 'notranslate'}"
            ></v-select>
          </v-col>
          <v-col cols="12" md="12" class="pa-0 mb-2">
            <label class="text-left font-label">Availability</label>
            <v-select
              class="type-selection notranslate"
              solo
              :items="availabilityItems"
              v-model="user.availability"
              :label="user.availability"
              @click:clear="$nextTick(() => user.availability = null)"
              :disabled="!canUpdate"
              hide-details
              :menu-props="{'content-class' : 'notranslate'}"
          ></v-select>
          </v-col>
          <v-col cols="12" md="12" class="pa-0 pb-3" v-if="user.status === '4'">
            <BaseTextAreaField
              class="text-input notranslate"
              solo
              inputLabel="Reason"
              rows="2"
              placeholder="Reason"
              no-resize
              name="reason"
              v-model.trim="user.reason"
              :status="errors('reason').length ? 'error': 'normal'"
              :error-messages="errors('reason')"
               @input="$v.user.reason.$touch()"
              @blur="$v.user.reason.$touch()"
              :disabled="!canUpdate"
            >
            </BaseTextAreaField>
          </v-col>
      </v-row>
      <template v-if="canUpdate">
      <v-divider></v-divider>
      <v-row class="ma-0">
        <v-col class="d-flex justify-end">
          <v-btn
          :loading="loading"
          class="save-btn btn-purple"
          type="submit">{{buttonText}}</v-btn>
        </v-col>
      </v-row>
      </template>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  required, email, maxLength, minLength, numeric, requiredIf,
} from 'vuelidate/lib/validators';
import { mask } from 'vue-the-mask';
import _ from 'lodash';
import { url } from '@/helpers';
// import EventBus from '@/helpers/event-bus';
import BaseInput from '@/components/common/BaseInput';
import BaseTextAreaField from '@/components/common/BaseTextAreaField';
import DatePicker from '@/components/common/DatePicker';
import CustomComboBox from '@/components/common/CustomComboBox';
import AutoCompleteField from '@/components/common/AutoCompleteField';
import Upload from '@/components/common/Upload';

export default {
  components: {
    AutoCompleteField,
    BaseInput,
    BaseTextAreaField,
    CustomComboBox,
    DatePicker,
    Upload,
  },
  directives: {
    mask,
  },
  props: {
    creatingNewUser: {
      type: Boolean,
      default: false,
    },
    userObj: {
      type: Object,
      default: () => {},
    },
    canUpdate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('profile', ['skillsets', 'interests', 'cameraEquipments', 'lensEquipments', 'accessories', 'styles', 'subjects', 'writerInterests', 'agreements']),
    ...mapGetters('admin', ['creativePayTiers']),
    buttonText() {
      return this.newUser ? 'Add' : 'Save';
    },
    newUser() {
      return !this.userObj.id;
    },
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.user[field].$dirty) return errors;
        switch (field) {
          case 'firstName':
            if (!this.$v.user.firstName.required) {
              errors.push('Please provide your first name.');
            }
            if (!this.$v.user.firstName.maxLength) {
              errors.push('First name must be less than 50 characters length.');
            }
            break;
          case 'lastName':
            if (!this.$v.user.lastName.required) {
              errors.push('Please provide your last name.');
            }
            if (!this.$v.user.lastName.maxLength) {
              errors.push('Last name must be less than 50 characters length.');
            }
            break;
          case 'email':
            if (!this.$v.user.email.required) {
              errors.push('Please provide email address.');
            }
            if (!this.$v.user.email.email) { errors.push('Please provide a valid email address.'); }
            break;
          case 'number':
            if (!this.$v.user.number.minLength) { errors.push('Please provide valid Phone Number'); }
            break;
          case 'skillsets':
            if (!this.$v.user.skillsets.required) { errors.push('Please select atleast one skillset'); }
            break;
          case 'portfolio':
            if (!this.$v.user.portfolio.required) { errors.push('Please provide your website'); }
            if (!this.$v.user.portfolio.url) { errors.push('Please provide valid url'); }
            break;
          case 'instagramUrl':
            if (!this.$v.user.instagramUrl.url) { errors.push('Please provide valid url'); }
            break;
          case 'facebookUrl':
            if (!this.$v.user.facebookUrl.url) { errors.push('Please provide valid url'); }
            break;
          case 'zipcode':
            if (!this.$v.user.zipcode.minLength) {
              errors.push('Zipcode is required');
            }
            if (!this.$v.user.zipcode.validZipcode) {
              errors.push('Invalid Zipcode');
            }
            break;
          case 'createdDate':
            if (!this.$v.user.createdDate.required) {
              errors.push('Please provide your Create Date.');
            }
            break;
          case 'otherSkillset':
            if (!this.$v.user.otherSkillset.required) {
              errors.push('Please provide your Other Skill Set');
            }
            break;
          case 'reason':
            if (!this.$v.user.reason.required) {
              errors.push('Please provide Reason');
            }
            break;
          default:
            break;
        }
        return errors;
      };
    },
    tags() {
      if (this.user.skillsets) {
        const skillsets = _.map(this.user.skillsets, 'name');
        const photoVideoEditor = skillsets.some((x) => ['Photographer', 'Videographer', 'Video Editor'].includes(x));
        const copyWriter = skillsets.some((x) => ['Copywriter'].includes(x));

        return _.compact([
          photoVideoEditor && {
            name: 'cameraEquipments',
            title: 'Camera Equipment',
            items: this.cameraEquipments,
            values: this.user.cameraEquipments,
            type: 'combo',
          },
          photoVideoEditor && {
            name: 'lensEquipments',
            title: 'Lens Equipment',
            items: this.lensEquipments,
            values: this.user.lensEquipments,
            type: 'combo',
          },
          photoVideoEditor && {
            name: 'accessories',
            title: 'Accessories',
            items: this.accessories,
            values: this.user.accessories,
            type: 'combo',
          },
          photoVideoEditor && {
            name: 'interestIds',
            title: 'Interest Tags',
            items: this.interests,
            values: this.user.interestIds,
            type: 'select',
          },
          copyWriter && {
            name: 'styleIds',
            title: 'Styles',
            items: this.styles,
            values: this.user.styleIds,
            type: 'select',
          },
          copyWriter && {
            name: 'writerInterestIds',
            title: 'Writer Interests',
            items: this.writerInterests,
            values: this.user.writerInterestIds,
            type: 'select',
          },
          copyWriter && {
            name: 'subjectIds',
            title: 'Writer Subjects',
            items: this.subjects,
            values: this.user.subjectIds,
            type: 'select',
          },
        ]);
      }
      return [];
    },
  },
  methods: {
    ...mapActions('admin', ['registerUser', 'getUserDetails', 'updateUser']),
    setRadius(radius) {
      this.user.radius = radius;
    },
    getText(id, aObj, type) {
      const obj = _.find(aObj, (x) => x.id === id);
      return _.get(obj, type);
    },
    deleteAgreementFile(index, id) {
      this.user.agreements.splice(index, 1);
      if (id) {
        this.user.deletedAgreementFileIds.push(id);
      }
    },
    uploadAgreements(files) {
      if (files && files.length) {
        _.forEach(files, (file) => {
          this.user.agreements.push(file);
        });
      }
    },
    async submitForm(e) {
      e.preventDefault();
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        const result = this.user.id
          ? await this.updateUser(this.user)
          : await this.registerUser(this.user);
        if (result.success) {
          this.$emit('close', true);
        }
        this.loading = false;
      }
    },
    hasOtherSkillset() {
      return _.find(this.user.skillsets, { name: 'Other' });
    },
    updateOtherSkillName(val) {
      if (val.trim()) {
        this.user.otherSkillset = val;
        this.user.skillsets = _.map(this.user.skillsets, (skillset) => {
          if (skillset.name === 'Other') {
          // eslint-disable-next-line no-param-reassign
            skillset = Object.assign({}, skillset, { skillSetName: val });
          }
          return skillset;
        });
      }
    },
    showTierSelection(skill) {
      return _.includes(['Photographer', 'Videographer', 'Talent'], skill.name);
    },
    tierOptions(skill) {
      if (skill.name === 'Talent') {
        if ((this.newUser && !skill.tier) || !skill.tier) {
          // eslint-disable-next-line no-param-reassign
          skill.tier = ['model'];
        }
        const payTiers = _.filter(
          this.creativePayTiers, (tier) => tier.skillset.name === 'Talent',
        );
        return _.concat(_.map(payTiers, (tier) => (
          {
            text: `${_.capitalize(tier.tier)} ($${tier.rate})`,
            value: tier.tier,
          }
        )));
      }
      if (skill.name === 'Videographer' || skill.name === 'Photographer') {
        if ((this.newUser && !skill.tier) || !skill.tier) {
          // eslint-disable-next-line no-param-reassign
          skill.tier = 'advanced';
        }
        const payTiers = _.filter(
          this.creativePayTiers, (tier) => tier.skillset.name === skill.name,
        );
        return _.concat(_.map(payTiers, (tier) => (
          {
            text: `${_.capitalize(tier.tier)} ($${tier.rate})`,
            value: tier.tier,
          }
        )));
      }
      const payTiers = _.filter(
        this.creativePayTiers, (tier) => tier.skillset.name === skill.name,
      );
      return _.map(payTiers, (tier) => (
        {
          text: `${_.capitalize(tier.tier)} ($${tier.rate}/hr)`,
          value: tier.tier,
        }
      ));
    },
  },
  validations: {
    user: {
      firstName: {
        required,
        maxLength: maxLength(50),
      },
      lastName: {
        required,
        maxLength: maxLength(50),
      },
      email: {
        required,
        email,
      },
      number: {
        minLength: minLength(12),
      },
      skillsets: {
        required,
      },
      createdDate: {
        required,
      },
      portfolio: {
        required,
        url,
      },
      instagramUrl: {
        url,
      },
      facebookUrl: {
        url,
      },
      zipcode: {
        numeric,
        minLength: minLength(5),
        // maxLength: maxLength(10),
        // eslint-disable-next-line func-names
        required,
        validZipcode: (value) => /^[0-9A-Z]+$/.test(value),
      },
      otherSkillset: {
        // eslint-disable-next-line func-names
        required: requiredIf(function () {
          return _.find(this.user.skillsets, { name: 'Other' });
        }),
      },
      reason: {
        // eslint-disable-next-line func-names
        required: requiredIf(function () {
          return this.user.status === '4';
        }),
      },
    },
  },
  data() {
    return {
      user: {
        firstName: '',
        lastName: '',
        email: '',
        number: null,
        skillsets: [],
        interestIds: [],
        lensEquipments: [],
        cameraEquipments: [],
        accessories: [],
        writerInterestIds: [],
        styleIds: [],
        subjectIds: [],
        agreementsFiles: [],
        createdDate: new Date().toISOString().substr(0, 10),
        role: 'creative',
        availability: '1',
        status: null,
        portfolio: null,
        instagramUrl: null,
        facebookUrl: null,
        slackId: null,
        zipcode: null,
        deletedAgreementFileIds: [],
        agreements: [],
        reason: '',
      },
      statusItems: [
        { text: 'Active', value: '1' },
        { text: 'Blocked', value: '3', disabled: !this.$can('delete', 'creative management') },
      ],
      availabilityItems: [
        { text: 'Available', value: '1' },
        { text: 'Unavailable', value: '2' },
      ],
      loading: false,
    };
  },

  mounted() {
    if (this.userObj.id) {
      this.user = Object.assign(this.user, this.userObj);
      if (this.hasOtherSkillset()) {
        this.user.otherSkillset = _.get(_.find(this.user.skillsets, { name: 'Other' }), 'skillSetName', '');
      }
      if (this.userObj.status === '0' || this.userObj.status === '4') {
        this.statusItems.push({ text: 'Rejected', value: '4' });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.upload-field {
  margin-bottom: 30px !important;
}
::v-deep .v-input__prepend-outer {
  display: none;
}
::v-deep .v-input__prepend-outer {
  visibility: hidden !important;
}
.save-btn {
  box-shadow: 0 3px 4px 0 rgba(62, 90, 102, 0.16);
  width: 140px;
  height: 40px;
}
.upload-text {
  font-family: $fontFamily1;
  font-size: 12px;
  letter-spacing: -0.01px;
  color: #00b0d1;
}
.delete-btn {
  width: 74px;
  height: 17px;
  font-family: $fontFamily1;
  font-size: 14px;
  color: #ff0808;
}
.upload-text:hover {
  cursor: pointer;
}
::v-deep .mdi .mdi-paperclip {
  display: none !important;
}
::v-deep .type-selection {
  .v-input__slot {
    padding-left: 0 !important;
    padding-right: 0 !important;
    box-shadow: none !important;
  }
  .v-text-field__details {
    margin-bottom: 0!important;
  }
}

::v-deep .date-selection.type-selection .v-input__slot {
  padding-left: 12px !important;
  border-color: $silver !important;
}
::v-deep .v-label {
  padding-left: 5px !important;
}
.form-heading {
  font-family: $fontFamily1;
  font-size: 16px;
  font-weight: bold;
}
// ::v-deep .v-text-field--is-booted,
// .v-text-field--placeholder{
//   height: auto !important;
// }
::v-deep.text-input > .input-field > .v-input__control > .v-input__slot {
  box-shadow: none;
  border-radius: 2px;
  border: solid 1px $silver;
  background-color: #ffffff;
}
::v-deep .v-select__slot {
//   min-width: 240px;
  // border: 1px solid $silver;
//   border-radius: 6px;
  box-shadow: none !important;
  padding-left: 10px;
}
.client-tag-form {
  ::v-deep .input-field {
    margin: 0;
    padding: 0;
    margin-bottom: 10px !important;
  }
  ::v-deep .v-input--radio-group.v-input--radio-group--row .v-radio {
    margin-right: 0;
  }
}

.date-selection {
  ::v-deep  .v-text-field {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }
  ::v-deep .v-input__slot {
    border: 1px solid $silver;
    border-bottom-color: $silver;
    padding-left: 10px;
  }
}
    ::v-deep .v-list-item__title {
      font-family: $fontFamily1;
      font-size: 14px !important;
    }
    .combo-box {
      margin-bottom: 10px !important;
    }
    .auto-complete {
      margin-bottom: 0;
    }
    .tags.auto-complete {
      margin-bottom: 10px;
    }
    .upload-button {
      font-family: $fontFamily1;
      font-size: 12px;
      letter-spacing: -0.01px;
      color: $secondary1;
      span {
        cursor: pointer !important;
      }
    }
    .agreement-file {
      color: $primary2;
      margin: 4px;
      font-size: 14px !important;
      border-radius: 6px;
      font-family: $fontFamily1;
    }
    .skill-chip {
      color: $primary2;
    }
  .tier-selection {
    max-width: 190px;
    &.talent {
      max-width: 245px;
    }
    ::v-deep .v-select__slot {
      padding-left: 5px;
    }
    ::v-deep .v-select__selections input {
      max-width: 0 !important;
    }
    ::v-deep .v-input__slot {
      min-height: 33px !important;
      padding: 0 5px !important;
    }
    ::v-deep .v-input__append-inner {
      margin-top: 4px !important;
      padding: 0;
    }
  }
</style>
<style lang="scss">
  .tier-selection-dropdown {
    .v-list-item {
      text-transform: capitalize;
    }
  }
</style>
