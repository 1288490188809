<template>
  <div>
    <v-container fluid class="content-wrapper pa-0">
      <v-form class="register-form" ref="form" @submit="submitForm">
        <v-row class="d-flex align-center ma-0">
          <v-col cols="12" md="12" class="px-5 pt-2">
            <BaseInput
              class="text-input mb-4"
              solo
              v-model.trim="user.firstName"
              inputLabel="First Name"
              placeholder
              name="firstName"
              :status="errors('firstName').length ? 'error': 'normal'"
              :error-messages="errors('firstName')"
              @input="$v.user.firstName.$touch()"
              @blur="$v.user.firstName.$touch()"
              :disabled="!canUpdate"
            />
            <BaseInput
              class="text-input mb-4"
              solo
              v-model.trim="user.lastName"
              inputLabel="Last Name"
              placeholder
              name="lastName"
              :status="errors('lastName').length ? 'error': 'normal'"
              :error-messages="errors('lastName')"
              @input="$v.user.lastName.$touch()"
              @blur="$v.user.lastName.$touch()"
              :disabled="!canUpdate"
            />
            <BaseInput
              class="text-input mb-4"
              solo
              inputLabel="Email"
              name="email"
              v-model.trim="user.email"
              :status="errors('email').length ? 'error': 'normal'"
              :error-messages="errors('email')"
              @input="$v.user.email.$touch()"
              @blur="$v.user.email.$touch()"
              :disabled="!canUpdate"
            />
            <BaseInput
              class="text-input mb-4"
              solo
              type="tel"
              v-mask="'###-###-####'"
              v-model.trim="user.number"
              inputLabel="Phone"
              placeholder
              name="phone"
              :error-messages="errors('number')"
              @input="$v.user.number.$touch()"
              @blur="$v.user.number.$touch()"
              :disabled="!canUpdate"
            />
            <label class="text-left pt-3 font-label">Role</label>
            <v-select
              class="type-selection pb-3 notranslate"
              solo
              flat
              :items="roleItems"
              v-model="user.role"
              :disabled="!canUpdate || !!user.id"
              hide-details=""
              :menu-props="{'content-class' : 'notranslate'}"
            ></v-select>
            <BaseInput
              v-if="user.role === 'executive' || user.role === 'producer' "
              class="text-input mb-4"
              solo
              inputLabel="Calendly"
              name="calendly"
              key="calendly"
              v-model.trim="user.calendly"
              :status="errors('calendly').length ? 'error': 'normal'"
              :error-messages="errors('calendly')"
              @input="$v.user.calendly.$touch()"
              @blur="$v.user.calendly.$touch()"
              :disabled="!canUpdate"
            />
            <template v-if="user.id">
              <label class="text-left font-label">Status</label>
              <v-select
                class="type-selection notranslate"
                dense
                solo
                flat
                :items="statusItems"
                v-model="user.status"
                :disabled="!canUpdate"
                :menu-props="{'content-class' : 'notranslate'}"
              ></v-select>
            </template>
            <template v-else>
              <BaseInput
                class="text-input mb-4"
                solo
                dense
                flat
                inputLabel="Password"
                key="password"
                placeholder
                name="password"
                v-model.trim="user.password"
                :type="'password'"
                :status="errors('password').length ? 'error': 'normal'"
                :error-messages="errors('password')"
                @input="$v.user.password.$touch()"
                @blur="$v.user.password.$touch()"
              />
              <BaseInput
                class="text-input mb-4"
                solo
                dense
                flat
                inputLabel="Confirm Password"
                placeholder
                name="confirmPassword"
                key="confirmPassword"
                v-model.trim="user.confirmPassword"
                :type="'password'"
                :status="errors('confirmPassword').length ? 'error': 'normal'"
                :error-messages="errors('confirmPassword')"
                @input="$v.user.confirmPassword.$touch()"
                @blur="$v.user.confirmPassword.$touch()"
              />
            </template>
            <template>
              <v-checkbox
                v-if="user.role === 'executive'"
                v-model="user.isDefaultContact"
                dense
                class='multi-check default-contact ml-n1'
                label="Make as default contact"
                :disabled="!canUpdate"
              > </v-checkbox>
            </template>
            <template>
              <v-checkbox
                v-if="user.role === 'producer'"
                v-model="user.isTrainee"
                dense
                class='multi-check default-contact ml-n1'
                label="Trainee Producer"
                :disabled="!canUpdate"
              > </v-checkbox>
            </template>
          </v-col>
        </v-row>
        <template v-if="canUpdate">
          <v-divider></v-divider>
          <v-row class="ma-0">
            <v-col class="d-flex justify-space-between" v-if="creatingNewUser">
              <v-btn
              text class="ml-4" color="primaryGray1" @click="$emit('close-modal')">Cancel</v-btn>
              <v-btn :loading="loading" class="btn-purple mr-4" type="submit" @click="createUser">
                Add
              </v-btn>
            </v-col>
            <v-col class="d-flex justify-end" v-else>
              <v-btn
              :loading="loading"
              class="save-btn btn-purple"
              type="submit">{{buttonText}}</v-btn>
            </v-col>
          </v-row>
        </template>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import {
  required, requiredIf, email, minLength, sameAs, maxLength,
} from 'vuelidate/lib/validators';
import { mask } from 'vue-the-mask';
import { url } from '@/helpers';
import BaseInput from '@/components/common/BaseInput';

export default {
  components: {
    BaseInput,
  },
  directives: {
    mask,
  },
  props: {
    creatingNewUser: {
      type: Boolean,
      default: false,
    },
    userObj: {
      type: Object,
      default: () => {},
    },
    canUpdate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isExecutive() {
      return this.userObj.role === 'producer' || this.userObj.role === 'executive';
    },
    buttonText() {
      return this.newUser ? 'Add' : 'Save';
    },
    newUser() {
      return !this.userObj.id;
    },
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.user[field].$dirty) return errors;
        const calendlyValidation = ((!this.user.isTrainee && this.user.role === 'producer') || (this.user.isDefaultContact && this.user.role === 'executive')) && (this.user.status === '1');
        switch (field) {
          case 'firstName':
            if (!this.$v.user.firstName.required) {
              errors.push('Please provide your first name.');
            }
            if (!this.$v.user.firstName.maxLength) {
              errors.push('First name must be less than 50 characters length.');
            }
            break;
          case 'lastName':
            if (!this.$v.user.lastName.required) {
              errors.push('Please provide your last name.');
            }
            if (!this.$v.user.lastName.maxLength) {
              errors.push('Last name must be less than 50 characters length.');
            }
            break;
          case 'email':
            if (!this.$v.user.email.required) {
              errors.push('Please provide email address.');
            }
            if (!this.$v.user.email.email) { errors.push('Please provide a valid email address.'); }
            break;
          case 'number':
            if (!this.$v.user.number.minLength) { errors.push('Please provide valid Phone Number'); }
            break;
          case 'calendly':
            // eslint-disable-next-line
            if (!this.$v.user.calendly.required && calendlyValidation) { errors.push('Please provide your calendly link'); }
            // eslint-disable-next-line
            if ( this.$v.user.calendly.$model && this.$v.user.calendly.$model.indexOf('calendly.com')<0) { errors.push('Please provide valid calendly url'); }
            break;
          case 'password':
            if (this.user.id) {
              break;
            }
            if (!this.$v.user.password.required) {
              errors.push('Please provide your new password.');
            }
            if (!this.$v.user.password.minLength) {
              errors.push('Password must be atleast 8 characters.');
            }
            if (!this.$v.user.password.strongPassword) {
              errors.push('Password must have atleast one number and one small character.');
            }
            break;
          case 'confirmPassword':
            if (this.user.id) {
              break;
            }
            if (!this.$v.user.confirmPassword.required) {
              errors.push('Please provide your confirm password.');
            }
            if (!this.$v.user.confirmPassword.sameAsPassword) {
              errors.push('Passwords do not match.');
            }
            break;
          default:
            break;
        }
        return errors;
      };
    },
  },
  methods: {
    ...mapActions('admin', ['registerStaff', 'getUserDetails', 'updateStaff']),
    async submitForm(e) {
      e.preventDefault();
      this.$v.$touch();
      if ((!this.$v.$invalid && !(this.isExecutive && (this.user.calendly && this.user.calendly.indexOf('calendly.com') < 0)))) {
        this.loading = true;
        const result = this.user.id
          ? await this.updateStaff(this.user)
          : await this.registerStaff(this.user);
        if (result.success) {
          this.$emit('close', true);
        }
        this.loading = false;
      }
    },
  },
  validations: {
    user: {
      firstName: {
        required,
        maxLength: maxLength(50),
      },
      lastName: {
        required,
        maxLength: maxLength(50),
      },
      email: {
        required,
        email,
      },
      calendly: {
        // eslint-disable-next-line func-names
        required: requiredIf(function () {
          // eslint-disable-next-line
          return ((!this.user.isTrainee && this.user.role === 'producer' ) || (this.user.isDefaultContact && this.user.role === 'executive' )) && (this.user.status === '1');
        }),
        url,
      },
      number: {
        minLength: minLength(12),
      },
      password: {
        // eslint-disable-next-line func-names
        required: requiredIf(function () {
          return !this.user.id;
        }),
        minLength() {
          return this.user.id || minLength(8);
        },
        strongPassword(currentPassword) {
          return (
            this.user.id
              || (/[a-z]/.test(currentPassword)
          && /[0-9]/.test(currentPassword))
          );
        },
      },
      confirmPassword: {
        // eslint-disable-next-line func-names
        required: requiredIf(function () {
          return !this.user.id;
        }),
        sameAsPassword: sameAs('password'),
      },
    },
  },
  data() {
    return {
      user: {
        firstName: '',
        lastName: '',
        email: '',
        number: null,
        password: '',
        confirmPassword: '',
        status: '1',
        role: 'executive',
        calendly: '',
        isDefaultContact: false,
        isTrainee: false,
      },
      roleItems: [
        { text: 'Production Manager', value: 'executive' },
        { text: 'Content Producer', value: 'producer' },
        { text: 'Editor', value: 'editor' },
        { text: 'Community Manager', value: 'community_manager' },
      ],
      statusItems: [
        { text: 'Active', value: '1' },
        { text: 'Blocked', value: '3', disabled: !this.$can('delete', 'producer management') },
      ],
      loading: false,
    };
  },

  mounted() {
    if (this.userObj.id) {
      this.user = Object.assign(this.user, this.userObj);
    }
  },
};
</script>

<style lang="scss" scoped>
.default-contact {
  width: fit-content;
}
::v-deep .v-input__prepend-outer {
  display: none;
}
::v-deep .v-input__prepend-outer {
  visibility: hidden !important;
}
.save-btn {
  box-shadow: 0 3px 4px 0 rgba(62, 90, 102, 0.16);
  width: 140px;
}
::v-deep .type-selection > .v-input__control > .v-input__slot {
  padding-left: 0 !important;
  padding-right: 0 !important;
  box-shadow: none !important;
}
::v-deep .v-label {
  padding-left: 5px !important;
}
.form-heading {
  font-family: $fontFamily1;
  font-size: 16px;
  font-weight: bold;
}
::v-deep.text-input > .input-field > .v-input__control > .v-input__slot {
  box-shadow: none;
  border-radius: 2px;
  border: solid 1px $silver;
  background-color: #ffffff;
}

::v-deep .v-select__slot {
  min-width: 240px;
  border: 1px solid $silver;
  border-radius: 6px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.07);
  padding-left: 10px;
}
</style>
