<template>
  <v-card flat>
    <!-- <v-card-title> -->
      <div class="px-5">
      <v-row class="my-0">
        <v-col class="d-flex align-center pb-1" cols=10>
          <span class="modal-header-title mr-1"> {{title}} </span>
        </v-col>
        <v-col class="d-flex justify-end pb-1">
          <v-icon color="darken-1" @click="$emit('close-modal')">mdi-close</v-icon>
        </v-col>
      </v-row>
      </div>
    <!-- </v-card-title> -->
    <v-divider></v-divider>
    <v-card-text class="form-body pa-0 pt-2">
      <!-- <div class="form-body pt-2"> -->
        <template v-if="this.role === 'creative'">
          <creative-form
            :userObj="creativeUser"
            @close="closeForm"
            :canUpdate="canUpdate"
          >
          </creative-form>
        </template>
        <template v-else-if="this.role === 'producer'">
          <div class="pa-4">
            <ProducerForm
              :userObj="producerUser"
              @close="closeForm"
              :canUpdate="canUpdate"
            >
            </ProducerForm>
          </div>
        </template>
        <template v-else>
          <client-form
            :userObj="clientUser"
            @close="closeForm"
            :canUpdate="canUpdate"
          >
          </client-form>
        </template>
      <!-- </div> -->
    </v-card-text>
  </v-card>
</template>

<script >
import _ from 'lodash';
import ClientForm from './ClientForm';
import CreativeForm from './CreativeForm';
import ProducerForm from './ProducerForm';

export default {
  components: {
    ClientForm,
    CreativeForm,
    ProducerForm,
  },

  props: {
    user: {
      type: Object,
      default: () => {},
    },
    role: {
      type: String,
      default: 'client',
    },
  },

  computed: {
    title() {
      return this.user.id ? `${this.canUpdate ? 'Edit' : 'View'} ${this.userRole}` : `Add New ${this.userRole}`;
    },
    canUpdate() {
      return this.user.id ? this.$can('edit', `${this.role} management`) : this.$can('create', `${this.role} management`);
    },
    userRole() {
      switch (this.role) {
        case 'client':
          return 'Customer';
        case 'producer':
          return 'Producer';
        default:
          return 'Creative';
      }
    },
    clientUser() {
      const userObj = Object.assign({}, this.user);
      const userProject = _.get(userObj, 'business.projects[0]', {});
      userObj.businessName = _.get(userObj, 'business.name', '');
      userObj.website = _.get(userObj, 'business.website', '');
      userObj.businessTypeId = _.get(userObj, 'business.businessType.id', '');
      userObj.instagram = _.get(userObj, 'business.instagram', '');
      userObj.facebook = _.get(userObj, 'business.facebook', '');
      userObj.linkedin = _.get(userObj, 'business.linkedin', '');
      userObj.youtube = _.get(userObj, 'business.youtube', '');
      userObj.agreements = _.get(userObj, 'business.agreements', []);
      userObj.projectName = userProject.name;
      userObj.projectDate = userProject.date || new Date().toISOString().substr(0, 10);
      userObj.subscriptionBlendIds = _.get(userObj, 'business.subscriptionBlends[0]', {}).id;
      userObj.roleName = _.get(userObj, 'role.name', '');
      return userObj;
    },

    creativeUser() {
      const userObj = Object.assign({}, this.user);
      userObj.portfolio = _.get(userObj, 'creativeProfile.portfolio', '');
      userObj.reason = _.get(userObj, 'creativeProfile.reason', '');
      userObj.skillsets = _.get(userObj, 'creativeProfile.skillsets');
      userObj.interestIds = _.map(_.get(userObj, 'creativeProfile.interests', []), 'id');
      userObj.accessories = _.get(userObj, 'creativeProfile.accessories', []);
      userObj.cameraEquipments = _.get(userObj, 'creativeProfile.cameraEquipments', []);
      userObj.lensEquipments = _.get(userObj, 'creativeProfile.lensEquipments', []);
      userObj.writerInterestIds = _.map(_.get(userObj, 'creativeProfile.writerInterests', []), 'id');
      userObj.styleIds = _.map(_.get(userObj, 'creativeProfile.styles', []), 'id');
      userObj.subjectIds = _.map(_.get(userObj, 'creativeProfile.subjects', []), 'id');
      return userObj;
    },

    producerUser() {
      const userObj = Object.assign({}, this.user);
      userObj.role = _.get(userObj, 'role.name');
      userObj.calendly = _.get(userObj, 'calendly');
      return userObj;
    },
  },

  methods: {
    closeForm(reload) {
      this.$emit('close-modal', reload);
    },
  },
};
</script>

<style scoped lang="scss">
  .v-card {
    border-radius: 0 !important;
  }
  .title {
    font-family: $fontFamily1 !important;
    font-size: 16px !important;
    font-weight: bold;
  }
  .header {
    position: relative;
    z-index: 2;
  }
  .form-body {
    max-height: 600px;
    overflow: auto;
  }
  ::v-deep .input-field {
    height: 40px;
    &.v-textarea {
      height: auto;
    }
  }
  ::v-deep .v-text-field.v-text-field--solo .v-input__control {
    min-height: 40px !important;
    height: auto !important;
  }
  ::v-deep .v-input__slot {
    margin-bottom: 4px;
  }
</style>
